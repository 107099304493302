import * as React from "react";
import { graphql } from "gatsby";
import { useIntl } from "react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import Course from "../components/course";

const ExperiencePage = ({ data }) => {
  const { formatMessage } = useIntl();
  const { header } = data;
  const title = formatMessage({ id: "experience" });

  return (
    <Layout whiteHeader>
      <Seo title={title} />
      <Hero title={header.imageAlt} media={header.image} />
      <Course fullPage />
    </Layout>
  );
};

export default ExperiencePage;

export const query = graphql`
  query ($locale: String!) {
    video: file(relativePath: { eq: "experience-video.mp4" }) {
      publicURL
    }

    videoPoster: file(relativePath: { eq: "experience-video-poster.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    header: contentfulImageWithText(
      metadata: { tags: { elemMatch: { name: { eq: "headerExperience" } } } }
      node_locale: { eq: $locale }
    ) {
      imageAlt
      image {
        gatsbyImageData(quality: 80)
        description
        title
      }
    }

    translations: contentfulTranslations(node_locale: { eq: $locale }) {
      translations {
        key
        value
      }
    }
  }
`;
