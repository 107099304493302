import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { graphql, useStaticQuery } from "gatsby";
import CourseMap from "./course-map";
import Link from "./link";
import ButtonLink from "./button-link";

const query = graphql`
  query {
    allContentfulLink(
      filter: {
        metadata: {
          tags: {
            elemMatch: {
              name: {
                in: ["experienceOnTheTrails", "experienceDiscoverDestinations"]
              }
            }
          }
        }
      }
    ) {
      nodes {
        id
        button
        buttonBackgroundColour
        link
        node_locale
        textColour
        target
        title
        metadata {
          tags {
            name
          }
        }
      }
    }
  }
`;

const Course = ({ fullPage }) => {
  const { formatMessage } = useIntl();
  const { allContentfulLink } = useStaticQuery(query);
  const { locale } = useIntl();
  const heading = formatMessage({ id: "courseHeading" });
  const atTheCamp = formatMessage({ id: "courseAtTheCamp" });
  const onTheTrails = formatMessage({ id: "courseOnTheTrails" });
  const beyondTheTrail = formatMessage({ id: "courseBeyondTheTrail" });
  const levelUp = formatMessage({ id: "courseLevelUp" });
  const footer = formatMessage({ id: "courseFooter" });

  const bookingButton = allContentfulLink.nodes.find(
    (item) =>
      item.node_locale === locale &&
      item.metadata.tags[0].name === "experienceOnTheTrails"
  );

  const discoverButton = allContentfulLink.nodes.find(
    (item) =>
      item.node_locale === locale &&
      item.metadata.tags[0].name === "experienceDiscoverDestinations"
  );

  return (
    <section
      className={`course${fullPage ? " course-full-page" : ""}`}
      aria-labelledby="course-heading"
    >
      <div className="container">
        <header>
          <h2
            id="course-heading"
            className="heading heading-large"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          {!fullPage && (
            <Link href="/experience" className="link">
              <FormattedMessage id="coursePrepare" />
            </Link>
          )}
        </header>
        <div className="course-wrapper">
          <div className="course-map">
            <CourseMap fullPage={fullPage} />
            <article className="course-location">
              <div
                dangerouslySetInnerHTML={{ __html: atTheCamp }}
                data-aos={fullPage ? "fade-up" : null}
              />
              {fullPage && (
                <ul // eslint-disable-line
                  role="list"
                >
                  <li style={{ "--color-card": "#AB8F84" }} data-aos="fade-up">
                    <div>
                      <p>
                        <FormattedMessage id="courseAtTheCampCardA" />
                      </p>
                    </div>
                  </li>
                  <li
                    style={{ "--color-card": "#6A4843" }}
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div>
                      <p>
                        <FormattedMessage id="courseAtTheCampCardB" />
                      </p>
                    </div>
                  </li>
                  <li
                    style={{ "--color-card": "#B09270" }}
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div>
                      <p>
                        <FormattedMessage id="courseAtTheCampCardC" />
                      </p>
                    </div>
                  </li>
                </ul>
              )}
            </article>
            <article className="course-location">
              <div
                dangerouslySetInnerHTML={{ __html: onTheTrails }}
                data-aos={fullPage ? "fade-up" : null}
              />
              {fullPage && (
                <ul // eslint-disable-line
                  role="list"
                >
                  <li style={{ "--color-card": "#728477" }} data-aos="fade-up">
                    <div>
                      <p>
                        <FormattedMessage id="courseOnTheTrailsCardA" />
                      </p>
                      {bookingButton && (
                        <ButtonLink
                          href={bookingButton.link}
                          backgroundColor={bookingButton.buttonBackgroundColour}
                          textColor={bookingButton.textColour}
                          button={bookingButton.button}
                          target={bookingButton.target}
                          className="course-booking-button"
                        >
                          {bookingButton.title}
                        </ButtonLink>
                      )}
                    </div>
                  </li>
                  <li
                    style={{ "--color-card": "#636050" }}
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div>
                      <p>
                        <FormattedMessage id="courseOnTheTrailsCardB" />
                      </p>
                    </div>
                  </li>
                  <li
                    style={{ "--color-card": "#94906B" }}
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div>
                      <p>
                        <FormattedMessage id="courseOnTheTrailsCardC" />
                      </p>
                    </div>
                  </li>
                </ul>
              )}
            </article>
            <article className="course-location">
              <div
                dangerouslySetInnerHTML={{ __html: beyondTheTrail }}
                data-aos={fullPage ? "fade-up" : null}
              />
              {fullPage && (
                <ul // eslint-disable-line
                  role="list"
                >
                  <li style={{ "--color-card": "#A1AAB3" }} data-aos="fade-up">
                    <div>
                      <p>
                        <FormattedMessage id="courseBeyondTheTrailCardA" />
                      </p>
                    </div>
                  </li>
                  <li
                    style={{ "--color-card": "#638080" }}
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div>
                      <p>
                        <FormattedMessage id="courseBeyondTheTrailCardB" />
                      </p>
                    </div>
                  </li>
                  <li
                    style={{ "--color-card": "#3B515E" }}
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div>
                      <p>
                        <FormattedMessage id="courseBeyondTheTrailCardC" />
                      </p>
                    </div>
                  </li>
                </ul>
              )}
            </article>
            <article className="course-location">
              <div
                dangerouslySetInnerHTML={{ __html: levelUp }}
                data-aos={fullPage ? "fade-up" : null}
              />
              {fullPage && (
                <ul // eslint-disable-line
                  role="list"
                >
                  <li style={{ "--color-card": "#C29852" }} data-aos="fade-up">
                    <div>
                      <p>
                        <FormattedMessage id="courseLevelUpCardA" />
                      </p>
                    </div>
                  </li>
                  <li
                    style={{ "--color-card": "#B97147" }}
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div>
                      <p>
                        <FormattedMessage id="courseLevelUpCardB" />
                      </p>
                    </div>
                  </li>
                  <li
                    style={{ "--color-card": "#C6AE85" }}
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div>
                      <p>
                        <FormattedMessage id="courseLevelUpCardC" />
                      </p>
                    </div>
                  </li>
                </ul>
              )}
            </article>
          </div>
        </div>
        {fullPage && (
          <footer>
            <h2
              className="heading heading-large"
              dangerouslySetInnerHTML={{ __html: footer }}
            />
            {discoverButton && (
              <ButtonLink
                href={discoverButton.link}
                backgroundColor={discoverButton.buttonBackgroundColour}
                textColor={discoverButton.textColour}
                button={discoverButton.button}
                target={discoverButton.target}
                className="experience-discover-button"
              >
                {discoverButton.title}
              </ButtonLink>
            )}
          </footer>
        )}
      </div>
    </section>
  );
};

export default Course;
